<template>
  <div id="contentUs">
    <t-header :nav-light="false"></t-header>
    <section
      class="bg-half bg-light d-table w-100"
      style="
        background: url('media/images/images/contact-detail.jpg') center center;
      "
    >
      <div class="bg-overlay bg-overlay-white"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">ANKET KATILIMCI AYDINLATMA METNİ</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <a @click="$router.push({ name: 'user.index' })">{{
                        $t("index.home")
                      }}</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      ANKET KATILIMCI AYDINLATMA METNİ
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!--Shape End-->

    <!-- questions section begin -->
    <div class="questions-section">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 col-md-12">
            <h1 class="turuncu-baslik">ANKET KATILIMCI AYDINLATMA METNİ</h1>

            <h3 class="turuncu-baslik">GİRİŞ</h3>
            <p>
              DORINSIGHT ARAŞTIRMA VE DANIŞMANLIK HİZMETLERİ TİCARET ANONİM
              ŞİRKETİ ( “Napolyon” veya “Şirket”), 7 Nisan 2016 tarihli ve 29677
              sayılı Resmi Gazete’de yayınlanmış olan 6698 sayılı Kişisel
              Verilerin Korunması Kanunu’na (“KVK Kanunu”), sektörünün mesleki
              davranış kurallarına tamamen uymaya kendini adamıştır. Şirket
              olarak yürüttüğümüz araştırmalarına katılanların ve süreç
              içerisinde yer alan tüm tarafların gizlilik ve Kişisel Verilerinin
              Korunması bizim için son derece önemli olup; Kişisel Verilerinizin
              güvenliği hususunda azami hassasiyet göstermekteyiz.
            </p>
            <p>
              Bu bilinçle, Şirket olarak her türlü kişisel verinin KVK Kanunu’na
              uygun olarak işlenerek, muhafaza edilmesine büyük önem
              atfetmekteyiz. Bu sorumluluğumuzun tam idraki ile KVK Kanunu’nda
              tanımlı şekli ile “Veri Sorumlusu” sıfatıyla, kişisel verilerinizi
              aşağıda izah edildiği surette ve mevzuat tarafından emredilen
              sınırlar çerçevesinde toplamakta, işlemekte ve gerekli olması
              halinde muhafaza etmekteyiz. Bu kapsamda, Kişisel Verilerinizi
              yalnızca araştırmalarımızı yürütmek için işbu Aydınlatma Metni
              sınırları içerisinde işleyeceğiz.
            </p>
            <p></p>
            <hr />
            <h3 class="turuncu-baslik">1. KONU</h3>

            <p>
              İşbu Aydınlatma Metninin konusu, tanımı Kişisel Verilerin
              Korunması Politikasında yer alan genel anketlere katılımcı olan
              Veri Sahibinin, Şirket ile paylaştığı Kişisel Verilerine ilişkin
              olarak KVK Kanunu başta olmak üzere Kişisel Verilerin korunmasına
              ilişkin ilgili mevzuat kapsamında Kişisel Verilerinin işlenmesine
              ilişkin Şirket’in hak ve yükümlülüklerini düzenlemektir.
            </p>

            <h3 class="turuncu-baslik">2. TOPLANABİLİR VERİLER</h3>
            <p>
              Toplanabilir verileriniz, aşağıda sayılan, açık rıza veya diğer
              haller kapsamındaki Kişisel Verileriniz veya Özel Nitelikli
              Kişisel Verilerinizin işlenmesine ilişkindir:
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Adınız, adresiniz,
              telefon numaranız, e-posta adresiniz, T.C. kimlik bilgileriniz,
              yaşınız, mesleğiniz, sosyo ekonomik statünüz, sizi özel olarak
              tanımlayan bilgileriniz ve benzeri diğer bilgiler dâhil olmak
              üzere Kişisel Verileriniz;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Irk veya etnik
              kökeniniz, siyasi görüşleriniz, dini veya felsefi inançlarınız,
              sendika üyeliğiniz, sağlığınız veya cinsel yaşamınız ve bunlarla
              sınırlı olmamak üzere gibi Özel Nitelikli Kişisel Verileriniz
            </p>

            <h3 class="turuncu-baslik">
              3. VERİ TOPLAMA YÖNTEMİ VE HUKUKİ SEBEBİ
            </h3>
            <h4 class="turuncu-baslik">
              Kişisel Verilerin Toplanması, İşlenmesi ve İşleme Amaçları
            </h4>

            <p>
              Kişisel verileriniz, Napolyon tarafından sağlanan hizmet ve
              Şirketimizin ticari faaliyetlerine bağlı olarak değişkenlik
              gösterebilmekle birlikte; otomatik ya da otomatik olmayan
              yöntemlerle, Napolyon’un birimleri ve ofisler, internet sitesi,
              sosyal medya mecraları, mobil uygulamalar ve benzeri vasıtalarla
              sözlü, yazılı ya da elektronik olarak toplanabilecektir.
            </p>

            <p>
              Napolyon’un sunduğu ürün ve hizmetlerden yararlandığınız müddetçe,
              Şirket çağrı merkezlerimizi veya internet sayfamızı
              kullandığınızda, internet sitelerimizi ziyaret ettiğinizde,
              Şirket’in düzenlediği eğitim, seminer veya organizasyonlara
              katıldığınızda Kişisel Verileriniz işlenebilecektir.
            </p>

            <p>
              Ayrıca, Kişisel Verileriniz aşağıda yer verilen amaçlar
              doğrultusunda Napolyon’un sunduğu ürün ve hizmetlerin belirlenen
              yasal çerçevede sunulabilmesi ve bu kapsamda sözleşme ve yasadan
              doğan mesuliyetlerini eksiksiz ve doğru bir şekilde yerine
              getirebilmesi için işlenebilecektir.
            </p>

            <p>
              Napolyon’un Kişisel Veri işleme amacı ve dayanağı kayıtlı
              bulundukları sicillerde belirtilmiş olan amaçların bütünüdür. Bu
              kapsamda Napolyon‘un amacı ile ilişkili olarak aşağıdakilerle
              sınırlı olmamak üzere, araştırma katılımcılarının Kişisel Verileri
              toplanabilmekte ve işlenebilmektedir:
            </p>

            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Pazar araştırması ve
              sosyal araştırma gerçekleştirmek; bu kapsamda, sizin anket
              araştırmasına katılmanız;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Kişisel Veri
              kayıtlarımızın doğrulama ve güncellenmesi;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> İstediğiniz hizmetleri
              sağlamaması da dahil olmak üzere, bize gönderdiğiniz sorulara ve
              taleplerinize yanıt verilmesi;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Çıkan anket sonuçları
              ile müşterilerimizin çeşitli ürün ve hizmetlerle ilgili
              tüketicilerin farkındalıklarını, kullanımlarını veya memnuniyetini
              anlamasını sağlamak; tutum ve kamuoyunu ölçmek için anketler ve
              odak grupları oluşturmak, yeni ürünleri tasarlamak veya test etmek
              ya da iletişim etkinliğini test etmek;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Şirketimiz tarafından
              sunulan hizmetlere ilişkin gerekli çalışmaların iş birimlerimiz
              tarafından yapılması;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> KVK Kanunu’nun 5. ve 6.
              maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
              dâhilinde işlenebilecektir.
            </p>

            <p>
              Şirket olarak, Kişisel Verilerin işlenmesi bakımından işbu amaç ve
              dayanağın dışına çıkmayacaktır.
            </p>

            <h3 class="turuncu-baslik">
              4. İŞLENEN KİŞİSEL VERİLERİN KİMLERE VE HANGİ AMAÇLA
              AKTARILABİLECEĞİ
            </h3>
            <p>
              Şirket, araştırma katılımcıların Kişisel Verilerini üçüncü
              şahıslara aşağıdaki şekillerde aktarabilmektedir:
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Hedef Holding Grup
              Şirketleri ve bağlı şirketleri (yurtdışında bulunanlar dahil) ile
              paylaşılması;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Şirket ve Şirket’in iş
              ilişkisi içerisinde olan tüzel ve gerçek kişiler tarafından
              sunulan ürün ve hizmetlerden sizleri faydalandırmak için gerekli
              çalışmaların iş birimlerimiz tarafından yapılması;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Şirket için veya
              sunduğu hizmetler ile bağlantılı olarak tedarikçileri ve
              danışmanları ile paylaşılması ve belirtilenler tarafından
              işlenmesine. Örneğin, anket sitelerini barındıracak, veri toplamak
              veya işleme hizmetleri;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Şirket’in sizden ayrıca
              alınacak onay ile müşterileri ile paylaşması için;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Yürürlükteki kanunlar
              uyarınca veya bir mahkeme celbine ya da bir resmi kurumun emrine
              cevaben;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Sınırlı kalmamak
              kaydıyla veri ekleme hizmetleri, veri doğrulama hizmetleri, veri
              tabanı eşleme hizmetleri, kodlama hizmetleri, veri segmentasyonu
              hizmetleri ve ödül, teşvik ve çekilişle ilgili hizmetler dahil
              olmak üzere Hedef Holding Grup şirketlerinin hizmet sağlayan
              yetkili temsilcileri ve/veya tedarikçileri ile paylaşılması;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Şirketimizin ticari ve
              iş stratejilerinin belirlenmesi ve uygulanması;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> Sınırlı kalmamak
              kaydıyla ilgili kişiyi tanımlayıcı bilgileri alan veya elde eden
              tarafın, kişisel tanımlayıcı bilgileri işbu Aydınlatma Metnine
              uygun olarak kullanmayı ve işbu Aydınlatma Metnine uygun olarak
              güvenliğini, bütünlüğünü ve gizliliğini korumayı ve sürdürmeyi
              kabul etmesi şartıyla, sınırlı kalmamak kaydıyla Şirket’in ticari
              varlıklarından herhangi birinin veya tamamının birleşmesi veya
              satın alınması dahil olmak üzere bir mülkiyet veya kontrol
              değişikliğiyle bağlantılı olarak;
            </p>
            <p>
              <i class="fa fa-arrow-circle-o-right"></i> KVK Kanunu’nun 8. ve 9.
              maddelerinde belirtilen kişisel veri işleme şartları ve amaçları
              çerçevesinde
            </p>

            <p>
              Şirket, işlediği Kişisel Verileri ilgili mevzuat ve Kurul
              kararlarında öngörülen yöntemler dâhilinde doğru ve güncel olarak
              muhafaza etmektedir. Şirket, işlenmesini gerektiren sebeplerin
              ortadan kalkması hâlinde Kişisel Verileri resen veya Katılımcının
              talebi üzerine silecek veya yok edecektir.
            </p>

            <h3 class="turuncu-baslik">
              5. KİŞİSEL VERİ SAHİBİNİN KVK KANUNU’NUN 11. MADDESİNDE SAYILAN
              HAKLARI
            </h3>

            <p>
              Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi,
              işbu Aydınlatma Metninde aşağıda düzenlenen yöntemlerle
              Şirketimize iletmeniz durumunda Şirketimiz talebiniz niteliğine
              göre en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır.
              Başvuruya cevabın CD, flash bellek gibi bir kayıt ortamında
              verilmesi halinde Kişisel Verileri Koruma Kurumu tarafından talep
              edilebilecek ücret kayıt ortamının maliyetini geçmeyecektir. Bu
              kapsamda Veri sahibi olarak;
            </p>

            <i class="fa fa-arrow-circle-o-right"></i> Kişisel Verilerinizin
            işlenip işlenmediğini öğrenme, <br />
            <i class="fa fa-arrow-circle-o-right"></i> Kişisel Verileriniz
            işlenmişse buna ilişkin bilgi talep etme, <br />
            <i class="fa fa-arrow-circle-o-right"></i> Kişisel Verilerinizin
            işlenme amacını ve bunların amacına uygun kullanılıp
            kullanılmadığını öğrenme, <br />
            <i class="fa fa-arrow-circle-o-right"></i> Yurt içinde veya yurt
            dışında Kişisel Verilerinizin aktarıldığı üçüncü kişileri bilme,
            <br />
            <i class="fa fa-arrow-circle-o-right"></i> Kişisel Verilerinizin
            eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini
            isteme ve bu kapsamda yapılan işlemin Kişisel Verilerinizin
            aktarıldığı üçüncü kişilere bildirilmesini isteme, <br />
            <i class="fa fa-arrow-circle-o-right"></i> KVK Kanununun ve ilgili
            diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen,
            işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde Kişisel
            Verilerinizin silinmesini veya yok edilmesini isteme ve bu kapsamda
            yapılan işlemin Kişisel Verilerinizin aktarıldığı üçüncü kişilere
            bildirilmesini isteme,<br />
            <i class="fa fa-arrow-circle-o-right"></i> İşlenen verilerin
            münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle
            kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz
            etme,<br />
            <i class="fa fa-arrow-circle-o-right"></i> Kişisel Verilerinizin
            kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız hâlinde
            zararın giderilmesini talep etme hakkına sahipsiniz.<br />

            <h3 class="turuncu-baslik">6. VERİ SORUMLUSUNA BAŞVURU USULÜ</h3>
            <p>
              KVK Kanunu’nun 13. maddesinin 1. fıkrası ve Veri Sorumlusuna
              Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca, yukarıda
              belirtilen haklarınızı kullanmak için

              <a
                href="http://napolyon.com/Content/Napolyon70/documents/Kisisel_Veri_Sahibi_Basvuru_Form.docx"
                target="_blank"
                >buraya</a
              >
              tıklayarak ulaşacağınız Kişisel Verilerin Korunması Kanunu linkin
              altında bulunan Kişisel Veri Sahibi Başvuru Formu’nu doldurarak
              bizzat elden veya noter kanalıyla, iadeli taahhütlü posta yoluyla
              ilgili Şirket adına, İnkılap Mh. Dr.Adnan Büyükdeniz Cad. No:4/2 Cessas Plaza Kat:3 D:7 Ümraniye/İstanbul adresine göndererek iletebilir
              veya ilgili formu taratarak
              <a href="mailto:napolyon@napolyon.com">napolyon@napolyon.com</a>
              adresine güvenli elektronik imza, mobil imza ya da tarafınızca
              Napolyon’a daha önce bildirilen ve sistemlerimizde kayıtlı olan
              elektronik posta adresinizi kullanmak suretiyle iletebilirsiniz.
              Şirketimiz, talebi kabul edebileceği gibi gerekçesini açıklayarak
              reddedebilir; cevabını ilgili kişiye yazılı olarak veya elektronik
              ortamda bildirir. Başvuruda yer alan talebin kabul edilmesi
              hâlinde Şirketimiz gereğini yerine getirir.
            </p>
            <p>
              Başvurunun reddedilmesi, verilen cevabın yetersiz bulunması veya
              süresinde başvuruya cevap verilmemesi hâllerinde; veri sahibi,
              cevabı öğrendiği tarihten itibaren otuz ve her hâlde başvuru
              tarihinden itibaren altmış gün içinde Kurula şikâyette bulunma
              hakkına sahiptir.
            </p>

            <h3 class="turuncu-baslik">7. KAPSAM</h3>

            <p>
              İşbu Aydınlatma Metni, Napolyon tarafından internet sitesinde
              yayınlanmış olan Kişisel Verilerin Korunması Politikasının hüküm
              ve koşullarını kapsamaktadır.
            </p>

            <h3 class="turuncu-baslik">8. SORULAR VE ŞİKAYETLER</h3>
            <p>
              İşbu Aydınlatma Metnine, uygulama veya araştırmaya katılım ile
              ilgili sorularınız veya endişeleriniz bulunması durumunda bizimle
              <a href="mailto:napolyon@napolyon.com">info@napolyon.com</a>
              e-mail adresi üzerinden iletişime geçebilirsiniz.
            </p>
            <hr />
            <form
              method="get"
              action="http://napolyon.com/Content/Napolyon70/documents/Kisisel_Veri_Sahibi_Basvuru_Form.docx"
            >
              <button class="btn-primary">
                <i class="fa fa-download"></i> Kişisel Veri Sahibi Başvuru Formu
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- questions section end -->

    <!-- Start Contact -->
    <t-up-footer></t-up-footer>
    <t-footer></t-footer>
  </div>
</template>

<script>
import Footer from "@/view/layouts/Footer";
import upFooter from "@/view/layouts/upFooter";
import Header from "@/view/layouts/Header";
import feather from "feather-icons";
export default {
  name: "ClarificationText",
  components: {
    "t-footer": Footer,
    "t-up-footer": upFooter,
    "t-header": Header,
  },
  mounted() {
    window.scrollTo(0, 0);
    feather.replace();
  },
};
</script>

<style scoped></style>
